import { ReactNode } from 'react'
import { LazyMotion } from 'framer-motion'
import React from 'react'

import { loadFeatures } from '../../lib/framer-motion'

type LayoutProps = {
  children: ReactNode
}

export function Layout({ children }: LayoutProps) {
  return (
    <LazyMotion features={loadFeatures} strict>
      {children}
    </LazyMotion>
  )
}

export default Layout
